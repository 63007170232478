//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StepLayout from './step-layout.vue';
import Button from '../components/button.vue';
import Radio from '../components/radio.vue';
import stepMixin from '../lib/step-mixin.js';
import congratulationsImg from '$resources/images/enhorabuena.png';

export default {
  components: {
    StepLayout,
    Button,
    Radio
  },
  mixins: [stepMixin],
  props: {},
  data: () => ({
    loading: false,
    congratulationsImg
  }),
  async created() {},
  methods: {
    onContinue() {
      this.loading = true;
      this.$emit('continue', {});
    }
  }
};
